import service from "../service.js";

export const searchSysLogs = params => {
    return service({
      method: 'POST',
      url: '/log/getLogs',
      params
    })
}

export const getOperLog = (id) => {
  return service({
    method: 'GET',
    url: '/log/getOperLog',
    params: {
      id
    }
  })
}