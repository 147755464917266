<template>
    <div class="LogList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="logForm" ref="logForm">
                    <el-form-item label="开始时间">
                        <el-date-picker v-model="logForm.startDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间">
                        <el-date-picker v-model="logForm.endDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <!-- <div class="clearfix">
                    <el-button type="primary" @click="$router.push({ name: 'role-create' })"
                        icon="el-icon-plus">新增</el-button>
                </div> -->
            </div>

            <!-- 列表 -->
            <el-table :data="logList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="logId" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="modelName" label="模块" align="center"> </el-table-column>
                <el-table-column prop="option" label="模块描述" align="center">
                </el-table-column>
                <el-table-column prop="operIp" label="ip地址" align="center">
                </el-table-column>
                <el-table-column prop="operName" label="操作账号" align="center">
                </el-table-column>
                <el-table-column prop="operUrl" label="请求接口" align="center">
                </el-table-column>
                <el-table-column prop="requestMethod" label="请求方式" align="center">
                </el-table-column>
                <el-table-column prop="operTime" label="请求时间" align="center" width="160">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情"
                            @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="logForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="logForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { searchSysLogs } from '@/api/log'
export default {
    data() {
        return {
            logList: [],
            logForm: {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        this.searchSysLogs()
    },
    methods: {
        handleSizeChange(val) {
            this.logForm.pageSize = val;
            this.logForm.pageNum = 1;
            this.searchSysLogs();
        },
        handleCurrentChange(val) {
            this.logForm.pageNum = val;
            this.searchSysLogs();
        },
        //查询
        searchSysLogs() {
            searchSysLogs(this.logForm).then(res => {
                if (res.code === '000000') {
                    this.logList = res.data.list
                    this.total = res.data.total
                    console.log(this.logList)
                    var count = 1;
                    this.logList.forEach((item) => {
                        item.logId = count++
                    })
                }

            })
        },
        handleDetail( {id} ){
            this.$router.push({
                name: 'sysLog-detail',
                params: {
                    id
                }
            })
        },
        //搜索
        handleSearch() {
            this.searchSysLogs(this.logForm);
        },
        //重置
        handleReset() {
            this.logForm = {};
            this.searchSysLogs(this.logForm);
        }
    }
}
</script>
<style lang="scss" scoped>
.LogList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

}
</style>